
[inert] {
    pointer-events: none;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    * {
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
}

html {
    &.dialog-open {
        overflow: hidden;
    }
}

#calculatorDialog {
    iframe {
        display: block;
        max-width: 100%;
        border: 0;
    }

    a {
        &:focus-visible {
            outline: 1px solid $color-blue;
        }
    }

    li {
        &:focus-visible {
            outline: 1px solid $color-blue;
        }
    }

    a,
    .link {
        color: $color-blue;
        outline: none;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            outline: none;
            text-decoration: underline;
        }

        &:focus-visible {
            outline: 1px solid $color-blue;
        }
    }

    a[href^="tel"] {
        color: inherit;
        text-decoration: none;
    }

    p,
    blockquote,
    ul,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    form,
    table,
    section,
    aside,
    article,
    dl,
    hr {
        margin: 0 0 $default-margin;
    }

    table {
        width: 100%;
        border-collapse: collapse;

        &.table-wrapper {
            margin: 0 0 $default-margin;
            overflow-x: scroll;

            table {
                margin: 0;

                tr {
                    th,
                    td {
                        @media (min-width: 1200px) {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }

    ul,
    ol {
        padding: 0;
        list-style-type: none;
    }

    .icon {
        display: inline-block;
        height: 40px;
        width: 40px;

        &--check {
            @include icon(check, $color-blue, 40px, 40px);
        }
    }
}
