[inert] {
  pointer-events: none;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
[inert] * {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

html.dialog-open {
  overflow: hidden;
}

#calculatorDialog iframe {
  display: block;
  max-width: 100%;
  border: 0;
}
#calculatorDialog a:focus-visible {
  outline: 1px solid #0064b3;
}
#calculatorDialog li:focus-visible {
  outline: 1px solid #0064b3;
}
#calculatorDialog a,
#calculatorDialog .link {
  color: #0064b3;
  outline: none;
  text-decoration: none;
}
#calculatorDialog a:hover, #calculatorDialog a:focus,
#calculatorDialog .link:hover,
#calculatorDialog .link:focus {
  color: #012a56;
  outline: none;
  text-decoration: underline;
}
#calculatorDialog a:focus-visible,
#calculatorDialog .link:focus-visible {
  outline: 1px solid #0064b3;
}
#calculatorDialog a[href^=tel] {
  color: inherit;
  text-decoration: none;
}
#calculatorDialog p,
#calculatorDialog blockquote,
#calculatorDialog ul,
#calculatorDialog ol,
#calculatorDialog h1,
#calculatorDialog h2,
#calculatorDialog h3,
#calculatorDialog h4,
#calculatorDialog h5,
#calculatorDialog h6,
#calculatorDialog form,
#calculatorDialog table,
#calculatorDialog section,
#calculatorDialog aside,
#calculatorDialog article,
#calculatorDialog dl,
#calculatorDialog hr {
  margin: 0 0 1.25rem;
}
#calculatorDialog table {
  width: 100%;
  border-collapse: collapse;
}
#calculatorDialog table.table-wrapper {
  margin: 0 0 1.25rem;
  overflow-x: scroll;
}
#calculatorDialog table.table-wrapper table {
  margin: 0;
}
@media (min-width: 1200px) {
  #calculatorDialog table.table-wrapper table tr th,
#calculatorDialog table.table-wrapper table tr td {
    min-width: 200px;
  }
}
#calculatorDialog ul,
#calculatorDialog ol {
  padding: 0;
  list-style-type: none;
}
#calculatorDialog .icon {
  display: inline-block;
  height: 40px;
  width: 40px;
}
#calculatorDialog .icon--check {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewBox='0 0 20 20'%3E%3Cstyle%3E.st0%7Bfill:%230064b3;%7D%3C/style%3E%3Cpath class='st0' d='M10,20c5.5,0,10-4.5,10-10S15.5,0,10,0S0,4.5,0,10C0,15.5,4.5,20,10,20z M10,1c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9C1,5,5,1,10,1z M8.9,12.6l-2-2l0.7-0.7l1.3,1.3l3.5-3.5l0.7,0.7L8.9,12.6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
}

#calculatorDialog h1,
#calculatorDialog .is-h1 {
  color: #0064b3;
  font-size: 3.25rem;
  line-height: 3.625rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  #calculatorDialog h1,
#calculatorDialog .is-h1 {
    font-size: 2.625rem;
    line-height: 3rem;
  }
}
#calculatorDialog h2,
#calculatorDialog .is-h2 {
  color: #0064b3;
  font-size: 2.625rem;
  line-height: 3.25rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 767.98px) {
  #calculatorDialog h2,
#calculatorDialog .is-h2 {
    font-size: 2rem;
    line-height: 2.625rem;
  }
}
#calculatorDialog h3,
#calculatorDialog .is-h3 {
  color: #0064b3;
  font-size: 2rem;
  line-height: 2.625rem;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}
#calculatorDialog h4,
#calculatorDialog .is-h4 {
  color: #0064b3;
  font-size: 1.625rem;
  line-height: 2.25rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
#calculatorDialog h5,
#calculatorDialog .is-h5 {
  color: #000;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}
#calculatorDialog h6,
#calculatorDialog .is-h6 {
  color: #000;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
#calculatorDialog h1 + .info,
#calculatorDialog h2 + .info,
#calculatorDialog h3 + .info,
#calculatorDialog h4 + .info,
#calculatorDialog h5 + .info,
#calculatorDialog h6 + .info {
  font-size: 0.875rem;
  margin-top: -1.25rem;
}

.sc-dialog-box {
  display: grid;
  background: #fff;
  color: #012a56;
  max-inline-size: min(90vw, 60ch);
  max-block-size: min(80vh, 100%);
  max-block-size: min(80dvb, 100%);
  margin: auto;
  padding: 0;
  position: fixed;
  inset: 0;
  border-radius: 1rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  min-width: 70vw;
  overflow: hidden;
  border: 0;
  transition: opacity 0.5s cubic-bezier(0.25, 0, 0.3, 1);
}
@media (prefers-reduced-motion: no-preference) {
  .sc-dialog-box {
    animation: scale-down 0.5s cubic-bezier(0.25, 0, 0.3, 1) forwards;
    animation-timing-function: cubic-bezier(0.5, -0.5, 0.1, 1.5);
  }
}
.sc-dialog-box:not([open]) {
  pointer-events: none;
  opacity: 0;
}
.sc-dialog-box::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  transition: backdrop-filter 0.5s ease;
}
.sc-dialog-box[loading] {
  visibility: hidden;
}
@media (prefers-reduced-motion: no-preference) {
  .sc-dialog-box[open] {
    animation: slide-in-up 0.5s cubic-bezier(0.25, 0, 0.3, 1) forwards;
  }
}
.sc-dialog-box .sc-step {
  margin-bottom: 0.75rem;
}
.sc-dialog-box .sc-is-h2 {
  color: #012a56;
}
.sc-dialog-box .sc-is-h2 span {
  font-weight: 600;
}
.sc-dialog-box .sc-orange {
  color: #ff5139;
}
.sc-dialog-box .dialog-wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: start;
  max-block-size: 80vh;
  max-block-size: 80dvb;
}
.sc-dialog-box .dialog-wrapper > article {
  overflow-y: auto;
  max-block-size: 100%; /* safari */
  overscroll-behavior-y: contain;
  display: grid;
  justify-items: flex-start;
  gap: 1rem;
  z-index: var(--layer-1);
  padding-inline: 1.5rem;
  padding-block: 1rem 0.5rem;
  position: relative;
}
.sc-dialog-box .dialog-wrapper > article .sc-is-h4 {
  color: #012a56;
  font-size: 1.3125rem;
  line-height: 1.75rem;
}
.sc-dialog-box .dialog-wrapper > header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding-block: 1rem;
  padding-inline: 1.5rem;
  background-color: #0064b3;
  color: #fff;
}
.sc-dialog-box .dialog-wrapper > header .sc-is-h3 {
  color: #fff;
  font-weight: 700;
  margin: 0;
}
.sc-dialog-box .dialog-wrapper > header > button {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 20 20'%3E%3Cstyle%3E.st0 %7Bfill: %23fff;%7D%3C/style%3E%3Cpath class='st0' d='M18,4.6L15.6,2L10,7.5l0,0l0,0L4.4,2L2,4.6L7.5,10L2,15.4L4.4,18l5.6-5.5l0,0l0,0l5.6,5.5l2.4-2.6L12.5,10L18,4.6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  padding: 0.75ch;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  place-items: center;
  border: 2px solid #fff;
}

.sc-step {
  display: none;
  width: 100%;
}
.sc-step.sc-active {
  display: block;
}

.sc-list--choices {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.sc-list--choices li {
  border: 2px solid #0064b3;
  padding: 1.5em 2em;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
  color: #0064b3;
}
.sc-list--choices li span {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  display: block;
}
.sc-list--choices li:hover, .sc-list--choices li:focus {
  background-color: #0064b3;
  color: #fff;
}

button {
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent;
}
button:hover, button:focus {
  outline: none;
}

.sc-iframe-wrapper {
  position: relative;
  margin-bottom: 10px;
}
.sc-iframe-wrapper iframe {
  width: 100%;
}
.sc-iframe-wrapper .sc-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  border-radius: 0;
  background-color: rgba(0, 100, 179, 0.2);
}
.sc-iframe-wrapper .sc-btn:hover, .sc-iframe-wrapper .sc-btn:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0064b3;
}

.sc-btn {
  position: relative;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.5rem;
  padding: 0.75em 1.5em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  border: 2px solid #ff5139;
  background-color: #ff5139;
  border-radius: 6px;
}
.sc-btn + .btn {
  margin-left: 15px;
}
.sc-btn:hover, .sc-btn:focus {
  color: #fff;
  text-decoration: none;
  border-color: #ff2406;
  background-color: #ff2406;
}
.sc-btn--link {
  padding: 0;
  background: transparent;
  color: #012a56;
  font-size: 1rem;
  line-height: normal;
  border: 0;
}
.sc-btn--link:hover, .sc-btn--link:focus {
  color: #ff5139;
  text-decoration: none;
  background-color: transparent;
}

.sc-dialog-prev {
  background: #012a56;
  border-radius: 0 6px 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5em 1em;
  color: #fff;
  font-size: 0.875rem;
  line-height: normal;
  font-weight: 700;
}

.sc-form {
  display: block;
}
.sc-form .sc-form-control {
  width: 100%;
}

.sc-form-wrapper {
  display: flex;
  flex-direction: column;
}
.sc-form-wrapper--float {
  width: 50%;
}
@media (min-width: 1200px) {
  .sc-form-wrapper--float {
    width: 100%;
  }
}
.sc-form-wrapper--float .sc-form-control,
.sc-form-wrapper--float .sc-btn {
  width: 100%;
}

.sc-form-control {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  height: auto;
  padding: 0.75em 1.5em;
  border: 1px solid #b8b9ba;
  background-color: #fff;
  border-radius: 6px;
}
.sc-form-control:focus {
  border-color: #0064b3;
  background-color: #fff;
  box-shadow: none;
}

.sc-form-group {
  margin-bottom: 10px;
}
.sc-form-group label {
  font-size: 0.875rem;
  opacity: 0.8;
  margin-bottom: 5px;
}
.sc-form-group.sc-has-error .sc-form-control {
  border-color: #db001b;
}
.sc-form-group.sc-has-error .sc-help-block {
  color: #db001b;
}
.sc-form-group--flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.sc-form-group--flex p {
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: normal;
}
.sc-form-group--flex p + .sc-btn {
  margin-left: 15px;
}

.sc-table .sc-border {
  border-top: 1px solid #012a56;
}

.sc-icon {
  display: inline-block;
  height: 10px;
  width: 20px;
}
.sc-icon--arrow {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='10px' viewBox='0 0 11.623 5.761'%3E%3Cpath id='Path_632' data-name='Path 632' d='M4.471,10.623a.2.2,0,0,1-.177-.088L.05,5.45a.232.232,0,0,1,0-.287L4.316.078A.215.215,0,0,1,4.626.056a.215.215,0,0,1,.022.309L4.411.65.514,5.317l4.134,4.952a.214.214,0,0,1-.022.309A.41.41,0,0,1,4.471,10.623Z' transform='translate(0.5 5.244) rotate(-90)' stroke='%23012a56' fill='%23012a56' stroke-width='1'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  rotate: -90deg;
  margin-left: 10px;
}

#calculatorDialog form label {
  display: block;
  cursor: pointer;
}
#calculatorDialog form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  user-select: none;
}
#calculatorDialog form select.form-control:not([size]):not([multiple]) {
  height: 57px;
}
#calculatorDialog .form {
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;
}
#calculatorDialog .form .form-message {
  padding: 10px 10px 0;
  color: #db001b;
  background: rgba(219, 0, 27, 0.1);
  border-left: 4px solid #db001b;
  margin: 0 0 10px;
}
#calculatorDialog .form .form-message > * {
  margin-bottom: 10px;
}
#calculatorDialog .form .form-message a {
  color: #db001b;
  text-decoration: underline;
}
#calculatorDialog .form .form-message--success {
  color: #28a745;
  background: rgba(40, 167, 69, 0.1);
  border-left: 4px solid #28a745;
}
#calculatorDialog .form .form-message--success a {
  color: #28a745;
}
#calculatorDialog .form .form-group {
  margin: 0 0 10px;
}
#calculatorDialog .form .form-group > * {
  margin: 0 0 10px;
}
#calculatorDialog .form .form-group > *:last-child {
  margin: 0;
}
#calculatorDialog .form .form-group .form-floating > label {
  font-size: 0.875rem;
  line-height: 1.625rem;
  padding: 11px 15px 12px;
  margin: 0;
}
#calculatorDialog .form .form-group > label {
  font-weight: 600;
}
#calculatorDialog .form .form-group.has-error .form-control {
  border-color: #db001b;
}
#calculatorDialog .form .form-group.has-error .help-block {
  color: #db001b;
}
#calculatorDialog .form .form-control-wrapper > *,
#calculatorDialog .form .form-floating > * {
  margin: 0 0 10px;
}
#calculatorDialog .form .form-control-wrapper > *:last-child,
#calculatorDialog .form .form-floating > *:last-child {
  margin: 0;
}
#calculatorDialog .form .form-control {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 3rem;
  height: auto;
  padding: 10px 15px;
  border: 1px solid #b8b9ba;
  background-color: #fff;
  border-radius: 0;
}
#calculatorDialog .form .form-control:focus {
  border-color: #0064b3;
  background-color: #fff;
  box-shadow: none;
}
#calculatorDialog .form .form-control--textarea {
  height: 120px;
}
#calculatorDialog .form .form-control--select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14px' height='14px' viewBox='0 0 20 20'%3E%3Cstyle%3E.st0 %7Bfill: %23000;%7D%3C/style%3E%3Cpath class='st0' d='M17.6,5.4c0.7-0.5,1.5-0.5,2,0.2l0,0c0.5,0.7,0.5,1.5-0.2,2l-8.3,7.1c-0.5,0.5-1.3,0.5-1.8,0l-8.8-7c-0.7-0.5-0.7-1.5-0.2-2c0.5-0.7,1.3-0.8,2-0.3l0,0l7.8,6.3L17.6,5.4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-position: center right 10px;
  background-color: #fff;
}
#calculatorDialog .form .form-control--select:focus-visible {
  outline: 1px solid #0064b3;
}
#calculatorDialog .form .form-control--check {
  position: relative;
  padding: 0 0 0 28px;
  margin: 0 0 10px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
}
#calculatorDialog .form .form-control--check label {
  margin: 0;
}
#calculatorDialog .form .form-control--check:last-child {
  margin: 0;
}
#calculatorDialog .form .form-control--check input[type=radio],
#calculatorDialog .form .form-control--check input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
#calculatorDialog .form .form-control--check input[type=radio] + i,
#calculatorDialog .form .form-control--check input[type=checkbox] + i {
  position: absolute;
  left: 0;
  top: 7px;
  display: block;
  width: 18px;
  height: 18px;
  border: 2px solid #b8b9ba;
}
#calculatorDialog .form .form-control--check input[type=radio] + i::before,
#calculatorDialog .form .form-control--check input[type=checkbox] + i::before {
  position: absolute;
  top: 1px;
  left: 1px;
  display: none;
  width: 12px;
  height: 12px;
  content: "";
  background-color: #0064b3;
}
#calculatorDialog .form .form-control--check input[type=radio]:checked + i::before,
#calculatorDialog .form .form-control--check input[type=checkbox]:checked + i::before {
  display: block;
}
#calculatorDialog .form .form-control--check input[type=radio]:focus-visible + i,
#calculatorDialog .form .form-control--check input[type=checkbox]:focus-visible + i {
  outline: 1px solid #0064b3;
}
#calculatorDialog .form .form-control--check input[type=radio] + i {
  border-radius: 50%;
}
#calculatorDialog .form .form-control--check input[type=radio] + i::before {
  border-radius: 50%;
}
#calculatorDialog ::-webkit-input-placeholder {
  color: #b8b9ba;
  font-style: italic;
}
#calculatorDialog ::-moz-placeholder {
  color: #b8b9ba;
  font-style: italic;
}
#calculatorDialog :-ms-input-placeholder {
  color: #b8b9ba;
  font-style: italic;
}
#calculatorDialog select::-ms-expand {
  display: none;
}
#calculatorDialog select option[disabled] {
  display: none;
}