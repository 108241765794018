#calculatorDialog {
    form {
        label {
            display: block;
            cursor: pointer;
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            user-select: none;

            &.form-control {
                &:not([size]):not([multiple]) {
                    height: 57px;
                }
            }
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        margin: 0 0 30px;

        .form-message {
            padding: 10px 10px 0;
            color: $color-red;
            background: rgba($color-red, .1);
            border-left: 4px solid $color-red;
            margin: 0 0 10px;

            > * {
                margin-bottom: 10px;
            }

            a {
                color: $color-red;
                text-decoration: underline;
            }

            &--success {
                color: $color-green;
                background: rgba($color-green, .1);
                border-left: 4px solid $color-green;

                a {
                    color: $color-green;
                }
            }
        }

        .form-group {
            margin: 0 0 10px;

            > * {
                margin: 0 0 10px;

                &:last-child {
                    margin: 0;
                }
            }

            .form-floating {
                > label {
                    font-size: rem(14px);
                    line-height: rem(26px);
                    padding: 11px 15px 12px;
                    margin: 0;
                }
            }

            > label {
                font-weight: $font-weight-medium;
            }

            &.has-error {
                .form-control {
                    border-color: $color-red;
                }

                .help-block {
                    color: $color-red;
                }
            }
        }

        .form-control-wrapper,
        .form-floating {
            > * {
                margin: 0 0 10px;

                &:last-child {
                    margin: 0;
                }
            }
        }

        .form-control {
            font-family: $text-font;
            font-size: $text-size;
            line-height: $line-height;
            height: auto;
            padding: 10px 15px;
            border: 1px solid $color-gray;
            background-color: $color-white;
            border-radius: 0;

            &:focus {
                border-color: $color-blue;
                background-color: $color-white;
                box-shadow: none;
            }

            &--textarea {
                height: 120px;
            }

            &--select {
                @include icon(select, $color-black, 14px, 14px);
                background-position: center right 10px;
                background-color: $color-white;

                &:focus-visible {
                    outline: 1px solid $color-blue;
                }
            }

            &--check {
                position: relative;
                padding: 0 0 0 28px;
                margin: 0 0 10px;
                border: 0;
                border-radius: 0;
                background-color: transparent;

                label {
                    margin: 0;
                }

                &:last-child {
                    margin: 0;
                }

                input[type="radio"],
                input[type="checkbox"] {
                    opacity: 0;
                    position: absolute;

                    + i {
                        position: absolute;
                        left: 0;
                        top: 7px;
                        display: block;
                        width: 18px;
                        height: 18px;
                        border: 2px solid $color-gray;

                        &::before {
                            position: absolute;
                            top: 1px;
                            left: 1px;
                            display: none;
                            width: 12px;
                            height: 12px;
                            content: '';
                            background-color: $color-blue;
                        }
                    }

                    &:checked {
                        + i {
                            &::before {
                                display: block;
                            }
                        }
                    }

                    &:focus-visible {
                        + i {
                            outline: 1px solid $color-blue;
                        }
                    }
                }

                input[type="radio"] {
                    + i {
                        border-radius: 50%;

                        &::before {
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }

    ::-webkit-input-placeholder {
        color: $color-gray;
        font-style: italic;
    }

    ::-moz-placeholder {
        color: $color-gray;
        font-style: italic;
    }

    :-ms-input-placeholder {
        color: $color-gray;
        font-style: italic;
    }

    select::-ms-expand {
        display: none;
    }

    select option[disabled] {
        display: none;
    }
}