
.sc-step {
    display: none;
    width: 100%;

    &.sc-active {
        display: block;
    }
}

.sc-list {
    &--choices {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        li {
            border: 2px solid $color-blue;
            padding: 1.5em 2em;
            margin-bottom: 10px;
            border-radius: 6px;
            cursor: pointer;
            color: $color-blue;

            span {
                font-size: rem(20);
                font-weight: $font-weight-bold;
                text-align: center;
                display: block;
            }

            &:hover,
            &:focus {
                background-color: $color-blue;
                color: $color-white;
            }
        }
    }
}

button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
        outline: none;
    }
}

.sc-iframe-wrapper {
    position: relative;
    margin-bottom: 10px;

    iframe {
        width: 100%;
    }

    .sc-btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 1em;
        border-radius: 0;
        background-color: rgba($color-blue, .2);

        &:hover,
        &:focus {
            color: $color-white;
            text-decoration: none;
            background-color: $color-blue;
        }
    }
}

.sc-btn {
    $self: &;
    position: relative;
    display: inline-block;
    font-family: $heading-font;
    color: $color-white;
    font-size: rem(18px);
    line-height: rem(24px);
    padding: .75em 1.5em;
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-align: center;
    border: 2px solid $color-orange;
    background-color: $color-orange;
    border-radius: 6px;

    + .btn {
        margin-left: 15px;
    }

    &:hover,
    &:focus {
        color: $color-white;
        text-decoration: none;
        border-color: darken($color-orange, 10%);
        background-color: darken($color-orange, 10%);
    }

    &--link {
        padding: 0;
        background: transparent;
        color: $text-color;
        font-size: $text-size;
        line-height: normal;
        border: 0;

        &:hover,
        &:focus {
            color: $color-orange;
            text-decoration: none;
            background-color: transparent;
        }
    }
}

.sc-dialog-prev {
    background: $text-color;
    border-radius: 0 6px 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: .5em 1em;
    color: $color-white;
    font-size: rem(14px);
    line-height: normal;
    font-weight: $font-weight-bold;
}

.sc-form {
    display: block;

    .sc-form-control {
        width: 100%;
    }
}

.sc-form-wrapper {
    display: flex;
    flex-direction: column;

    &--float {
        width: 50%;

        @media (min-width: 1200px) {
            width: 100%;
        }

        .sc-form-control,
        .sc-btn {
            width: 100%;
        }
    }
}

.sc-form-control {
    font-family: $text-font;
    font-size: $text-size;
    line-height: 2rem;
    height: auto;
    padding: .75em 1.5em;
    border: 1px solid $color-gray;
    background-color: $color-white;
    border-radius: 6px;

    &:focus {
        border-color: $color-blue;
        background-color: $color-white;
        box-shadow: none;
    }
}

.sc-form-group {
    margin-bottom: 10px;

    label {
        font-size: rem(14px);
        opacity: .8;
        margin-bottom: 5px;
    }

    &.sc-has-error {
        .sc-form-control {
            border-color: $color-red;
        }

        .sc-help-block {
            color: $color-red;
        }
    }

    &--flex {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;

        p {
            margin-bottom: 0;
            font-size: rem(14px);
            line-height: normal;

            + .sc-btn {
                margin-left: 15px;
            }
        }
    }
}

.sc-table {
    .sc-border {
        border-top: 1px solid $text-color;
    }
}

.sc-icon {
    display: inline-block;
    height: 10px;
    width: 20px;

    &--arrow {
        @include icon(arrow, $text-color, 20px, 10px);
        rotate: -90deg;
        margin-left: 10px;
    }
}