#calculatorDialog {
    h1,
    .is-h1 {
        color: $color-blue;
        font-size: rem(52px);
        line-height: rem(58px);
        font-weight: $font-weight-bold;
        font-family: $heading-font;

        @media (max-width: 767.98px) {
            font-size: rem(42px);
            line-height: rem(48px);
        }
    }

    h2,
    .is-h2 {
        color: $color-blue;
        font-size: rem(42px);
        line-height: rem(52px);
        font-weight: $font-weight-medium;
        font-family: $heading-font;

        @media (max-width: 767.98px) {
            font-size: rem(32px);
            line-height: rem(42px);
        }
    }

    h3,
    .is-h3 {
        color: $color-blue;
        font-size: rem(32px);
        line-height: rem(42px);
        font-weight: $font-weight-light;
        font-family: $heading-font;
    }

    h4,
    .is-h4 {
        color: $color-blue;
        font-size: rem(26px);
        line-height: rem(36px);
        font-weight: $font-weight-bold;
        font-family: $heading-font;
    }

    h5,
    .is-h5 {
        color: $color-black;
        font-size: rem(16px);
        line-height: rem(24px);
        font-weight: $font-weight-light;
        font-family: $heading-font;
    }

    h6,
    .is-h6 {
        color: $color-black;
        font-size: rem(16px);
        line-height: rem(24px);
        font-weight: $font-weight-bold;
        font-family: $heading-font;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        + .info {
            font-size: rem(14px);
            margin-top: -$default-margin;
        }
    }
}