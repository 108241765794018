.sc-dialog-box {
    display: grid;
    background: $color-white;
    color: $text-color;
    max-inline-size: min(90vw, 60ch);
    max-block-size: min(80vh, 100%);
    max-block-size: min(80dvb, 100%);
    margin: auto;
    padding: 0;
    position: fixed;
    inset: 0;
    border-radius: 1rem;
    box-shadow: 0 0 20px rgba($color-black, .2);
    z-index: 9999;
    min-width: 70vw;
    overflow: hidden;
    border: 0;
    transition: opacity .5s cubic-bezier(.25, 0, .3, 1);

    @media (prefers-reduced-motion: no-preference) {
        animation: scale-down .5s cubic-bezier(.25,0,.3,1) forwards;
        animation-timing-function: cubic-bezier(.5,-.5,.1,1.5);
    }

    &:not([open]) {
        pointer-events: none;
        opacity: 0;
    }

    &::backdrop {
        background-color: rgba($color-black, .5);
        backdrop-filter: blur(5px);
        transition: backdrop-filter .5s ease;
    }

    &[loading] {
        visibility: hidden;
    }

    &[open] {
        @media (prefers-reduced-motion: no-preference) {
            animation: slide-in-up .5s cubic-bezier(.25, 0, .3, 1) forwards;
        }
    }

    .sc-step {
        margin-bottom: .75rem;
    }

    .sc-is-h2 {
        color: $text-color;

        span {
            font-weight: $font-weight-medium;
        }
    }

    .sc-orange {
        color: $color-orange;
    }

    .dialog-wrapper {
        display: grid;
        grid-template-rows: auto 1fr auto;
        align-items: start;
        max-block-size: 80vh;
        max-block-size: 80dvb;

        & > article {
            overflow-y: auto;
            max-block-size: 100%; /* safari */
            overscroll-behavior-y: contain;
            display: grid;
            justify-items: flex-start;
            gap: 1rem;
            z-index: var(--layer-1);
            padding-inline: 1.5rem;
            padding-block: 1rem .5rem;
            position: relative;

            .sc-is-h4 {
                color: $text-color;
                font-size: rem(21);
                line-height: rem(28);
            }
        }

        & > header {
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            align-items: center;
            padding-block: 1rem;
            padding-inline: 1.5rem;
            background-color: $color-blue;
            color: $color-white;

            .sc-is-h3 {
                color: $color-white;
                font-weight: $font-weight-bold;
                margin: 0;
            }

            & > button {
                @include icon(close, $color-white, 20px, 20px);
                border-radius: 50%;
                padding: .75ch;
                width: 50px;
                height: 50px;
                flex-shrink: 0;
                place-items: center;
                border: 2px solid $color-white;
            }
        }
    }
}