// Overall
$default-margin: 1.25rem;
$default-block-margin: 2.5rem;
$heading-font: 'Poppins',sans-serif;
$text-font: 'Poppins',sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-semibold: 500;
$font-weight-bold: 700;

$text-size: 1rem;
$line-height: 3rem;
$text-color: #012a56;

$color-white: #fff;
$color-blue: #0064b3;
$color-orange: #ff5139;

$color-blue-light: #c4c9d3;
$color-black: #000;
$color-black-off: #333;
$color-black-light: #666;
$color-gray: #b8b9ba;
$color-gray-1: #f5f7f8;
$color-gray-2: #eaeaea;
$color-gray-3: #f7f7f7;
$color-red: #db001b;
$color-yellow: #ffea50;
$color-green: #28a745;

$color-email: #0a88ff;
$color-newsletter: #0a88ff;
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-youtube: #f00;
$color-linkedin: #0077b5;
$color-instagram: #833ab4;
$color-pinterest: #bd081c;
$color-whatsapp: #25d366;
